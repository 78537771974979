<template>
  <div class="DisableBtn">
    <ColorTextBtn
      :type="row.isEnable ? 'danger' : 'primary'"
      @click="showDialog"
      :disabled="!!row.isEnable && row.fieldName === '订单号'"
      >{{ row.isEnable ? '禁用' : '启用' }}
    </ColorTextBtn>
    <el-dialog :title="row.isEnable ? '禁用字段' : '启用字段'" :visible.sync="dialogVisible" append-to-body width="50%">
      <div v-if="row.isEnable">确定要禁用该字段吗？</div>
      <div v-else>
        <avue-form ref="form" v-model="form" :option="option"></avue-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="enableOrDisable" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { option } from './option'
import fieldManagementApi from '@/api/system/fieldManagementApi'

export default {
  name: 'DisableBtn',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    },
    init: Function
  },
  components: {},
  data() {
    return {
      dialogVisible: false,
      option,
      loading: false,
      form: {
        exportType: []
      }
    }
  },
  computed: {},
  watch: {
    dialogVisible(n) {
      if (!n) {
        this.$refs['form'].resetFields()
      }
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true
    },
    enableOrDisable() {
      if (this.row.isEnable) {
        this.disableHandle()
      } else {
        this.enableHandle()
      }
    },
    async disableHandle() {
      const query = {
        id: this.row.id,
        isEnable: 0,
        templateUsageType:1,
      }
      try {
        this.loading = true
        const res = await fieldManagementApi.update(query)
        if ($SUC(res)) {
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.init()
        } else {
          this.$message.error('操作失败')
        }
      } catch {
      } finally {
        this.loading = false
      }
    },
    enableHandle() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let exportType
          if (this.form?.exportType?.length === 2) {
            exportType = 3
          } else {
            exportType = this.form?.exportType?.[0]
          }
          const query = {
            id: this.row.id,
            isEnable: 1,
            exportType,
            templateUsageType:1,
          }
          try {
            this.loading = true
            const res = await fieldManagementApi.update(query)
            if ($SUC(res)) {
              this.$message.success('操作成功')
              this.dialogVisible = false
              this.init()
            } else {
              this.$message.error('操作失败')
            }
          } catch {
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.DisableBtn {
  display: inline-block;
}

.redColor {
  color: $color-danger;
}

::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ccc;
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
</style>
